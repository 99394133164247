import { useEffect, useState } from 'react';

const useLocation = () => {
    const [userLocation, setUserLocation] = useState();
    const [locationError, setLocationError] = useState();

    const getLocation = async () => {
        try {
            const res = await fetch('https://geolocation-db.com/json/');
            const data = await res.json();
            setUserLocation(data);
        } catch (e) {
            setLocationError(e);
        }
    };

    useEffect(() => {
        getLocation();
    }, []);

    return {
        userLocation,
        locationError,
    };
};

export default useLocation;
