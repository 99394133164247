const donationLinks = {
    USD: {
        countryCodeList: ['US', 'USA'],
        donationPriceAndLink: [
            [
                '$1.50',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-36N381120Y416623JMMEN6UI',
            ],
            [
                '$5',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-4XD91949HB1340132MLZNO5I',
            ],
            [
                '$10',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-2MW66571T7554813EMLZNQEI',
            ],
            [
                '$25',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-6U994739GB996353KMLZNSPQ',
            ],
            [
                '$50',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-0F238052NE914484YMQFBOLY',
            ],
            [
                '$100',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-2WE31276EY008941YMLZNTKA',
            ],
        ],
    },
    EURO: {
        countryCodeList: [
            'AT',
            'BE',
            'BG',
            'HR',
            'CY',
            'CZ',
            'DK',
            'EE',
            'FI',
            'FR',
            'DE',
            'GR',
            'HU',
            'IE',
            'IT',
            'LV',
            'LT',
            'LU',
            'MT',
            'NL',
            'PL',
            'PT',
            'RO',
            'SK',
            'SI',
            'ES',
            'SE',
        ],
        donationPriceAndLink: [
            [
                '€1.50',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-32U31462S8814784XMMEOIOA',
            ],
            [
                '€5',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-34E296527L891374XMMEOIZY',
            ],
            [
                '€10',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-12586596XF343572PMMEOJEA',
            ],
            [
                '€25',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-9D822523LD976293DMMEOJMY',
            ],
            [
                '€50',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-2GK4150625761705JMQFBNLY',
            ],
            [
                '€100',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-8T865182E4141654HMMEOJXQ',
            ],
        ],
    },
    GBP: {
        countryCodeList: ['UK', 'GB'],
        donationPriceAndLink: [
            [
                '£1.50',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-6DR165482L131972CMMEOLRA',
            ],
            [
                '£5',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-3WB59751LW5134207MMEOL4A',
            ],
            [
                '£10',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-2HT99883EP578663YMMEOMEA',
            ],
            [
                '£25',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-2Y9928094M789074FMMEOMQI',
            ],
            [
                '£50',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-8GD77094KC626021HMQFBLXA',
            ],
            [
                '£100',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-6R5224248Y789562TMMEOM4Y',
            ],
        ],
    },
    JPY: {
        countryCodeList: ['JP', 'JPN'],
        donationPriceAndLink: [
            [
                '¥200',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-3HC11939D1096832VMQYX4IA',
            ],
            [
                '¥750',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-6RE61787E4776570GMQYX5XA',
            ],
            [
                '¥1,500',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-6RN290456G860064HMQYX7OQ',
            ],
            [
                '¥3,000',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-87G68737R7834805XMQYYASA',
            ],
            [
                '¥6,000',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-6LD066029V613571UMQYYB4Q',
            ],
            [
                '¥10,000',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-52694101568186452MQYYCZQ',
            ],
        ],
    },
    BRL: {
        countryCodeList: ['BR', 'BRA'],
        donationPriceAndLink: [
            [
                'R$10',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-3B4452450B1455610MQYYREI',
            ],
            [
                'R$25',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-4A990854900381716MQYYSOA',
            ],
            [
                'R$50',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-1VB00038XS1866421MQYYS4A',
            ],
            [
                'R$100',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-6L712637WG4459424MQYYTGI',
            ],
            [
                'R$250',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-16129891G7394240GMQYYTOA',
            ],
            [
                'R$500',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-56U62638CG1527536MQYYTXY',
            ],
        ],
    },
    CAD: {
        countryCodeList: ['CA', 'CAN'],
        donationPriceAndLink: [
            [
                '$1.50',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-6DN32674JY168093SMQYYJ6A',
            ],
            [
                '$5.00',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-98A57628G8831130CMQYYKKA',
            ],
            [
                '$10.00',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-00456720SA7600107MQYYKUI',
            ],
            [
                '$25.00',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-9KT04667EW810900JMQYYLAA',
            ],
            [
                '$50.00',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-37941884JH308584LMQYYLKA',
            ],
            [
                '$100.00',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-3RD83797FU672210CMQYYL2I',
            ],
        ],
    },
    AUD: {
        countryCodeList: ['AU', 'AUS'],
        donationPriceAndLink: [
            [
                '$2.00',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-7M048589PB9405847MQYYOZQ',
            ],
            [
                '$5.00',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-9BD49287Y3017694HMQYYPDY',
            ],
            [
                '$10.00',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-88J328337V0223512MQYYPPA',
            ],
            [
                '$25.00',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-70B90280HH591473SMQYYPXY',
            ],
            [
                '$50.00',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-02889987L9419451WMQYYQBY',
            ],
            [
                '$100.00',
                'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-5MS60632N1356291FMQYYQKI',
            ],
        ],
    },
};

export const findDonationLinks = countryCode => {
    const currencyEntry = Object.entries(donationLinks).find(entry =>
        entry[1].countryCodeList.includes(countryCode)
    );
    if (currencyEntry) {
        return currencyEntry[0];
    } else {
        return 'USD';
    }
};

export const findDonation = (currency, amount) => {
    return donationLinks[currency].donationPriceAndLink.find(donation => donation[0] === amount);
};

export const findClosestInCurrency = (currency, amount, newCurrency) => {
    const current = findDonation(currency, amount);
    const currentIndex = donationLinks[currency].donationPriceAndLink.indexOf(current);
    if (currentIndex > -1) {
        return donationLinks[newCurrency].donationPriceAndLink[currentIndex][0];
    } else {
        return null;
    }
};

export default donationLinks;
