import React from 'react';
import '../styles/donateModal.scss';
import useLocation from '../hooks/useLocation';
import donationLinks, {
    findDonationLinks,
    findDonation,
    findClosestInCurrency,
} from './donationLinks';
import {
    Select,
    Stack,
    Typography,
    MenuItem,
    Button,
    FormControl,
    InputLabel,
    OutlinedInput,
    Grid,
} from '@mui/material';

const DonateMonthly = () => {
    const [donationCurrency, setDonationCurrency] = React.useState('USD');
    const [donationAmount, setDonationAmount] = React.useState('');
    const { userLocation } = useLocation();

    React.useEffect(() => {
        if (userLocation?.country_code) {
            const setUserLocationDefault = findDonationLinks(userLocation.country_code);
            setDonationCurrency(setUserLocationDefault);
            setDonationAmount(
                findClosestInCurrency(donationCurrency, donationAmount, setUserLocationDefault)
            );
        }
    }, [userLocation]);

    const handleCurrencySelect = e => {
        setDonationCurrency(e.target.value);
        const matchingDonationInNewCurrency = findClosestInCurrency(
            donationCurrency,
            donationAmount,
            e.target.value
        );
        if (matchingDonationInNewCurrency) {
            setDonationAmount(matchingDonationInNewCurrency);
        }
    };

    const handleDonateClick = () => {
        window.open(findDonation(donationCurrency, donationAmount)[1], '_blank');
    };

    return (
        <Stack
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: 2,
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Typography variant="h3" sx={{ mb: 2 }}>
                Monthly Donation
            </Typography>
            <Typography variant="caption" sx={{ mb: 6, pl: 2, pr: 2, textAlign: 'center' }}>
                Recurring monthly donations help provide Earth Hero security and stability. You can
                cancel at any time.
            </Typography>
            <Stack sx={{ width: { md: 364, xs: 364 } }}>
                <FormControl>
                    <InputLabel id="currency-label">Currency</InputLabel>
                    <Select
                        sx={{ width: { md: 360, xs: 360 }, padding: 2, height: 40 }}
                        value={donationCurrency}
                        onChange={handleCurrencySelect}
                        labelId="currency-label"
                        id="currency"
                        input={<OutlinedInput label="Currency" />}
                    >
                        {Object.keys(donationLinks).map(currency => (
                            <MenuItem value={currency} key={currency}>
                                {currency}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Grid
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 1,
                        pt: 2,
                        pb: 4,
                        justifyContent: 'space-between',
                    }}
                >
                    {donationLinks[donationCurrency].donationPriceAndLink.map(([amount, _link]) => (
                        <Button
                            variant={amount === donationAmount ? 'contained' : 'outlined'}
                            onClick={() => setDonationAmount(amount)}
                            sx={{ flex: '0 47%', height: '40px', marginBottom: '2%' }}
                        >
                            {amount}
                        </Button>
                    ))}
                </Grid>
                <Button
                    variant="contained"
                    onClick={handleDonateClick}
                    sx={{ minWidth: 350, padding: 2, height: 40 }}
                >
                    DONATE {donationAmount} WITH PAYPAL
                </Button>
            </Stack>
        </Stack>
    );
};

export default DonateMonthly;
