import React from 'react';
import { Typography, Box, Stack, Button, Container } from '@mui/material';
import { Link } from 'gatsby-material-ui-components';
import Layout from '@gaia/Layout';

import SEO from '../components/seo';
import empowersActionImage from '../images/empower-actions.jpeg';
import improveLivesImage from '../images/improve-lives.jpeg';
import supportEarthImage from '../images/support-earth.jpeg';
import donateJarImage from '../images/donate-jar.svg';
import DonateMonthly from '../components/DonateMonthly';

const WhatDonationDoes = ({ title, summary, image }) => (
    <Stack textAlign="center" sx={{ width: { xs: 368, md: 368 } }}>
        <Box
            component="img"
            src={image}
            alt=""
            sx={{ display: 'inline-block', marginBottom: { xs: 2, sm: 4 } }}
            loading="lazy"
        />
        <Box sx={{ pr: 4, pl: 4 }}>
            <Typography variant="h4" sx={{ mb: 2 }}>
                {title}
            </Typography>
            <Typography variant="body1">{summary}</Typography>
        </Box>
    </Stack>
);

const TipJar = ({ title, summary, link }) => {
    const handleOnClick = () => {
        window.open(link, '_blank');
    };
    return (
        <Box
            textAlign="center"
            sx={{
                backgroundColor: theme => theme.palette.background.default,
                width: { sm: 368 },
                marginLeft: { xs: 4, sm: 4 },
                marginRight: { xs: 4, sm: 4 },
                padding: { xs: 2, sm: 4 },
                paddingTop: { xs: 4, sm: 8 },
                borderRadius: 4,
                border: 0,
                boxShadow: 2,
                borderColor: theme => theme.palette.grey.main,
            }}
        >
            <Box
                component="img"
                src={donateJarImage}
                alt=""
                sx={{ display: 'inline-block', marginBottom: { xs: 2, sm: 4 } }}
                loading="lazy"
            />
            <Typography variant="h4" sx={{ mb: 1 }}>
                {title}
            </Typography>
            <Typography variant="body1" sx={{ height: { sm: 100 } }}>
                {summary}
            </Typography>
            <Box sx={{ paddingTop: { md: 4, xs: 2 } }}>
                <Button
                    onClick={handleOnClick}
                    variant="contained"
                    sx={{
                        backgroundColor: theme => theme.palette.secondary.main,
                        hover: {
                            backgroundColor: theme => theme.palette.secondary.dark,
                        },
                    }}
                >
                    Tip now
                </Button>
            </Box>
        </Box>
    );
};

const Donate = () => (
    <Layout container={false}>
        <SEO title="Donate" />
        <Box
            flexBasis="100%"
            sx={{
                backgroundColor: theme => theme.palette.primary.dark,
                paddingTop: { xs: 6, sm: 10 },
                paddingBottom: { xs: 4, sm: 10 },
                minHeight: { sm: '850px', md: '850px' },
            }}
        >
            <Container maxWidth="lg">
                <Stack>
                    <Typography
                        variant="h1"
                        sx={{
                            mb: { xs: 2, sm: 4 },
                            color: theme => theme.palette.primary.contrast,
                        }}
                    >
                        Help Support Our Mission
                    </Typography>
                    <Typography
                        paragraph
                        variant="subtitle1"
                        sx={{ mb: 4, color: theme => theme.palette.primary.contrast }}
                    >
                        <Link href={'#donate-monthly'} color="secondary">
                            Donate a monthly,
                        </Link>{' '}
                        recurring amount to keep the Earth Hero lights on or participate in our Tip
                        Jar Campaign.
                    </Typography>
                    <Typography
                        paragraph
                        variant="subtitle1"
                        sx={{ mb: 4, color: theme => theme.palette.primary.contrast }}
                    >
                        What is the Tip Jar Campaign?
                    </Typography>
                    <Typography
                        paragraph
                        variant="body1"
                        sx={{ color: theme => theme.palette.primary.contrast }}
                    >
                        We want to hear what projects you’re most excited about! The Tip Jar
                        Campaign is a fundraiser where you can contribute to specific projects by
                        tipping towards your preferred choice. At the end of the campaign Earth Hero
                        will develop and launch the project that received the most support!
                    </Typography>
                </Stack>
            </Container>
        </Box>
        <Box
            sx={{
                position: 'relative',
                paddingTop: { xs: 4 },
                top: { sm: '-400px', md: '-400px' },
                marginBottom: { sm: '-300px', md: '-300px' },
            }}
        >
            <Container maxWidth="lg">
                <Stack
                    component="section"
                    alignItems="center"
                    sx={{
                        flexDirection: { sm: 'row' },
                        justifyContent: 'center',
                        gap: { xs: 4, sm: 0 },
                        mb: { xs: 4, sm: 8 },
                    }}
                >
                    <TipJar
                        title="Add 50 More Actions"
                        summary="This project adds 50 new Earth Hero actions related to pets, children, driving system change, and other great ways to advance your climate journey."
                        link={'https://www.paypal.com/donate?campaign_id=AWVFJZZ2SUYSW'}
                    />
                    <TipJar
                        title="Launch a Web App"
                        summary="This project creates a web app version of Earth Hero. This way, you can easily access Earth Hero through a web browser."
                        link={'https://www.paypal.com/donate?campaign_id=3H49WQMQF3XZG'}
                    />
                </Stack>
                <Stack component="section" alignItems="center" sx={{ mb: { xs: 9, sm: 11 } }}>
                    <Typography
                        variant="h2"
                        sx={{ mb: 2, textAlign: { xs: 'center', sm: 'left' } }}
                    >
                        What Your Donation Does
                    </Typography>
                    <Typography
                        variant="body1"
                        paragraph
                        sx={{ mb: { xs: 4, sm: 8 }, width: { sm: '60%' } }}
                        textAlign="center"
                    >
                        Earth Hero is a non-profit team of volunteers working to address the climate
                        emergency and improve the health of our shared planet. Your support helps us
                        increase the positive impact of all Earth Heroes and cover our operating
                        costs.
                    </Typography>
                    <Stack sx={{ flexDirection: { sm: 'row' }, gap: 4 }}>
                        <WhatDonationDoes
                            title="Empowers Action"
                            summary="Earth Hero provides tools and resources that help hundreds of thousands fight climate change through practical actions."
                            image={empowersActionImage}
                        />
                        <WhatDonationDoes
                            title="Improves Lives"
                            summary="Whether adjusting your transportation, eating, or pushing for leaders to act, Earth Hero provides personalized guidance to help lead more fulfilling and sustainable lives."
                            image={improveLivesImage}
                        />
                        <WhatDonationDoes
                            title="Supports Earth"
                            summary="This planet needs you. We cannot do this alone. A collective effort is necessary so future generations can enjoy this unique and wonderful world."
                            image={supportEarthImage}
                        />
                    </Stack>
                </Stack>
                <Stack
                    component="section"
                    alignItems="center"
                    gap={16}
                    sx={{ mb: { xs: 9, sm: 11 }, flexDirection: { sm: 'row' }, gap: { sm: 7 } }}
                >
                    <Box
                        id="donate-monthly"
                        flexBasis="50%"
                        sx={{
                            backgroundColor: theme => theme.palette.grey.light,
                            padding: { xs: 2, sm: 4 },
                        }}
                        order={{ xs: 2, sm: 1 }}
                    >
                        <DonateMonthly />
                    </Box>
                    <Box
                        flexBasis="50%"
                        textAlign="left"
                        order={{ xs: 1, sm: 2 }}
                        sx={{
                            padding: { xs: 2, sm: 4 },
                        }}
                    >
                        <Typography variant="h3" sx={{ mb: 2 }}>
                            Where does your money go with monthly donation?
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ mb: 3 }}>
                            At Earth Hero, we are committed to acting as ethical stewards of your
                            gifts. Your support ensures that Earth Hero is 100% free for everyone
                            worldwide. It funds the technology and tools we use to create, improve,
                            distribute, and spread the message about better ways of living.
                        </Typography>
                    </Box>
                </Stack>
            </Container>
        </Box>
    </Layout>
);

export default Donate;
